<template>
  <div
    class="textarea-input"
    :class="{ 'has-errors': hasErrors }">
    <label
      v-if="label.length"
      :for="inputId"
      :class="theme ? null : labelClass"
      class="control-label"
      :style="labelStyles">
      <span v-html="label"></span>
      <span
        v-if="required"
        class="text-red-4">
        *
      </span>
    </label>
    <div class="flex">
      <textarea
        :value="modelValue"
        :class="textareaClasses"
        :style="textareaTheme"
        :rows="rows"
        :placeholder="placeholder"
        :maxlength="maxLength"
        :minlength="minLength"
        @keydown.enter="checkPreventNewLine"
        @paste="checkPreventPaste"
        @input="checkText"></textarea>
    </div>
    <span
      v-if="hasErrors"
      class="error-box mt-2">
      {{ inputErrors.join(", ") }}
    </span>
  </div>
</template>

<script>
import textAreaRegex from "@/app/javascript/utils/editor/editorRegexPatterns";
import computedStyle from "@/components/planner/app/javascript/mixins/computedStyle";

export default {
  name: "TextareaInput",
  mixins: [computedStyle],
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    inputId: {
      type: String,
      default: ""
    },
    labelClass: {
      type: String,
      required: false,
      default: "typeset-6"
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    errors: {
      type: Array,
      default: () => []
    },
    rows: {
      type: String,
      default: "2"
    },
    theme: {
      type: Object,
      default: () => {}
    },
    maxLength: {
      type: String,
      default: ""
    },
    minLength: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    pattern: {
      type: RegExp,
      required: false,
      default: null
    },
    preventPaste: {
      type: Boolean,
      required: false,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    resize: {
      type: String,
      default: "both"
    },
    preventNewLine: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue", "errored-pattern"],
  computed: {
    inputErrors() {
      return this.errors || [];
    },
    hasErrors() {
      return this.inputErrors.length > 0;
    },
    textareaTheme() {
      if (this.theme) {
        return {
          paddingLeft: this.theme.textarea.paddingLeft,
          paddingRight: this.theme.textarea.paddingRight,
          borderRadius: this.theme.textarea.borderRadius,
          textTransform: this.theme.textarea.textTransform,
          fontFamily: this.theme.textarea.fontFamily,
          fontSize: this.theme.textarea.fontSize,
          fontWeight: this.theme.textarea.fontWeight,
          outline: this.theme.textarea.outline,
          "--textarea-color": this.theme.textarea.color,
          "--textarea-hover-color": this.theme.textarea.hover.color,
          "--textarea-focus-color": this.theme.textarea.focus.color,
          "--textarea-background-color": this.theme.textarea.backgroundColor,
          "--textarea-hover-background-color":
            this.theme.textarea.hover.backgroundColor,
          "--textarea-focus-background-color":
            this.theme.textarea.focus.backgroundColor,
          "--textarea-border-color": this.theme.textarea.borderColor,
          "--textarea-hover-border-color":
            this.theme.textarea.hover.borderColor,
          "--textarea-focus-border-color":
            this.theme.textarea.focus.borderColor,
          "--textarea-box-shadow": this.theme.textarea.boxShadow,
          "--textarea-hover-box-shadow": this.theme.textarea.hover.boxShadow,
          "--textarea-focus-box-shadow": this.theme.textarea.focus.boxShadow,
          "--textarea-placeholder-color": this.theme.textarea.placeholder.color
        };
      }
      return null;
    },
    labelStyles() {
      if (this.theme) {
        return this.computedStyle(this.theme, "labelStyles");
      }
      return null;
    },
    textareaClasses() {
      return [
        this.theme ? "themed-textarea" : "form-control",
        {
          bordered: this.bordered,
          [`resize-${this.resize}`]: this.resize !== "both"
        }
      ];
    }
  },
  methods: {
    checkText($event) {
      const { value } = $event.target;
      if (value.length && this.pattern && !this.pattern.test(value)) {
        this.$emit("errored-pattern");
      } else {
        this.$emit("update:modelValue", value.replace(textAreaRegex, ""));
      }
    },
    checkPreventNewLine(e) {
      if (this.preventNewLine) {
        e.preventDefault();
      }
    },
    checkPreventPaste(e) {
      if (this.preventPaste) {
        e.preventDefault();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.themed-textarea {
  // Set some defaults, in case these aren't set in the custom theme.
  --textarea-color: #343434;
  --textarea-hover-color: #343434;
  --textarea-focus-color: #343434;
  --textarea-background-color: #efefef;
  --textarea-border-color: #efefef;
  --textarea-hover-background-color: #ffffff;
  --textarea-hover-border-color: #121212;
  --textarea-box-shadow: inset 0 2px 4px 0 rgba(0, 37, 84, 0.08);

  color: var(--textarea-color);
  background-color: var(--textarea-background-color);
  border-color: var(--textarea-border-color);
  box-shadow: var(--textarea-box-shadow);

  // Interaction styles (hover/focus) must use css variables
  &:hover {
    color: var(--textarea-hover-color);
    background-color: var(--textarea-hover-background-color);
    border-color: var(--textarea-hover-border-color);
    box-shadow: var(--textarea-hover-box-shadow);
  }

  &:focus {
    color: var(--textarea-focus-color);
    background-color: var(--textarea-focus-background-color);
    border-color: var(--textarea-focus-border-color);
    box-shadow: var(--textarea-focus-box-shadow);
  }

  &::placeholder {
    color: var(--textarea-placeholder-color);
  }
}
</style>
